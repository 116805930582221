<template>
  <div class="p-3">
    <div class="row">
      <div class="col-xl-9 col-lg-9 col-md-9 col-12 h-100">
        <appProgressSteps :propsData="stepId" :shId="shId"></appProgressSteps>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-12 h-100">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <img
                  src="../../assets/img/cv-screen-marketing-1.jpeg"
                  style="height: 77px"
                  class=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-9 col-md-9 col-12" id="step4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3">
                <h5>Additional Details</h5>
                <div class="underline"></div>
              </div>
            </div>

            <div class="row">
              <div
                class="alert alert-danger mb-5 col-12"
                v-if="checkValidation.length"
              >
                <p
                  class="fs-800 mb-0"
                  v-for="(item, index) in checkValidation"
                  :key="index"
                >
                  {{ item }}
                </p>
              </div>

              <div class="col-lg-6 col-md-6 col-12">
                <label for="last_name" class="d-inline pr-3"
                  >I have maiden/previous/alias names</label
                >
                <b-form-radio-group
                  id="i_have_a_previous_name"
                  :options="optionsAliasNameYesNo"
                  class="d-inline"
                  v-model="i_have_a_previous_name"
                ></b-form-radio-group>
              </div>
              <div class="col-lg-6 col-md-6 col-12 text-right">
                <appModalPreviousNamesNew
                  :projectId="id"
                  :productId="1"
                  :previous_names_status="i_have_a_previous_name"
                  :previous_names="previous_names"
                  v-if="i_have_a_previous_name == 'Yes'"
                >
                </appModalPreviousNamesNew>
              </div>
            </div>

            <div class="row mt-2" v-if="i_have_a_previous_name == 'Yes'">
              <div class="col-12 table-responsive">
                <table class="table table-bordered table-striped table-sm m-0">
                  <thead>
                    <tr>
                      <th>Name type</th>
                      <th>First name</th>
                      <th>Middle name</th>
                      <th>Last name</th>
                      <th>Date of change</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in previous_names" :key="index">
                      <td>{{ item.name_type }}</td>
                      <td>{{ item.first_name }}</td>
                      <td>{{ item.middle_name }}</td>
                      <td>{{ item.last_name }}</td>
                      <td>{{ item.date_of_change }}</td>
                      <td>
                        <div>
                          <appModalPreviousNamesEdit
                            :projectId="id"
                            :productId="1"
                            :propsNameData="item"
                            :propsNameIndex="index"
                            :previous_names="previous_names"
                            :previous_names_status="i_have_a_previous_name"
                            class="d-inline"
                          >
                          </appModalPreviousNamesEdit>
                          <a
                            href="javascript: void(0)"
                            class="p-2 d-inline"
                            @click="fnDeletePreviousNames(index)"
                            ><i class="far fa-times-circle text-info"></i
                          ></a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-lg-6 col-md-6 col-12">
                <label for="last_name" class="d-inline pr-3"
                  >I have lived at the same address for the last 5 years</label
                >
                <b-form-radio-group
                  :options="optionsPreviousAddressYesNo"
                  class="d-inline"
                  id="i_have_same_address"
                  disabled-field="notEnabled"
                  v-model="i_have_same_address"
                ></b-form-radio-group>
              </div>
              <div class="col-lg-6 col-md-6 col-12 text-right">
                <appModalPreviousAddressNew
                  :previous_address_status="i_have_same_address"
                  :previous_address="previous_address"
                  :policeCheckId="id"
                  v-if="i_have_same_address == 'No'"
                ></appModalPreviousAddressNew>
              </div>
            </div>

            <div class="row mt-1" v-if="i_have_same_address == 'No'">
              <div class="col-12 table-responsive">
                <table class="table table-bordered table-striped table-sm m-0">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Unit number</th>
                      <th>Street number</th>
                      <th>Street</th>
                      <th>Suburb</th>
                      <th>State</th>
                      <th>Post code</th>
                      <th>From date</th>
                      <th>To date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in previous_address" :key="index">
                      <td>{{ item.address_type }}</td>
                      <td>{{ item.unit_number }}</td>
                      <td>{{ item.street_number }}</td>
                      <td>{{ item.street }}</td>
                      <td>{{ item.suburb }}</td>
                      <td>{{ item.state }}</td>
                      <td>{{ item.post_code }}</td>
                      <td>{{ item.from_date }}</td>
                      <td>{{ item.to_date }}</td>
                      <td>
                        <div>
                          <appModalPreviousAddressEdit
                            class="d-inline"
                            :policeCheckId="id"
                            :propsAddressData="item"
                            :propsAddressIndex="index"
                            :previous_address="previous_address"
                            :previous_address_status="i_have_same_address"
                          ></appModalPreviousAddressEdit>
                          <a
                            href="javascript: void(0)"
                            class="p-2 d-inline"
                            @click="fnDeletePreviousAddress(index)"
                            ><i class="far fa-times-circle text-info"></i
                          ></a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="pt-3">
              <hr />
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <p class="text-info">
                  Upload a photo of yourself holding your photo ID
                </p>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                <wdImageAvatarPreview
                  :title="'Photo of yourself'"
                  :coverImgUrl="getPoliceCheckSingle.photo"
                  :fontAwClass="'fas fa-file-alt'"
                  :isFileDelete="true"
                  @delete-file="fnDeletePhoto()"
                ></wdImageAvatarPreview>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-12 pl-5 pr-5"></div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                <vue-dropzone
                  ref="docUploadDropzone"
                  id="docUploadDropzone"
                  :useCustomSlot="true"
                  :options="dropzoneOptions"
                  v-on:vdropzone-sending="fnSendingDocuemt"
                  @vdropzone-queue-complete="fnAfterSendingDocuemt"
                  class="quoteDocUpload"
                  style="height: 242px"
                >
                  <div class="dropzone-custom-content text-center p-5">
                    <div class="subtitle">
                      <i class="fas fa-cloud-upload-alt fs-2000 text-info"></i>
                    </div>
                    <h5 class="dropzone-custom-title">
                      Drag and drop to upload file!
                    </h5>
                    <div class="subtitle">
                      ...or click to select a file from your computer
                    </div>
                    <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                    <span v-if="loading">Uploading...</span>
                  </div>
                </vue-dropzone>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-right pt2000">
                <button
                  class="btn-secondary btn-sm mr-2 btn-w-100"
                  @click="fnPreviousStep()"
                >
                  Previous
                </button>
                <button class="btn-info btn-sm btn-w-100" @click="fnNextStep()">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-12">
        <div class="card h-overflow-700">
          <div class="card-body">
            <appStep4Ins></appStep4Ins>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import store from "../../store/modules/base.module";
import * as authService from "../../services/auth.service";
import vueDropzone from "vue2-dropzone";
import appStep4Ins from "../../components/policeCheck/step4Ins.vue";
import appProgressSteps from "../../components/policeCheck/progressSteps.vue";
import * as policeCheckService from "../../services/policeCheck.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import appModalPreviousNamesNew from "../../components/common/ModalPreviousNamesNew.vue";
import appModalPreviousAddressNew from "../../components/policeCheck/ModalPreviousAddressNew.vue";
import appModalPreviousAddressEdit from "../../components/policeCheck/ModalPreviousAddressEdit.vue";
import appModalPreviousNamesEdit from "../../components/common/ModalPreviousNamesEdit.vue";
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";
import * as miscellaneousService from "../../services/miscellaneous.service";

export default {
  name: "step4",
  props: ["policeCheckId"],
  components: {
    vueDropzone,
    appStep4Ins,
    appModalPreviousNamesNew,
    appModalPreviousAddressNew,
    appModalPreviousAddressEdit,
    appModalPreviousNamesEdit,
    appProgressSteps,
    wdImageAvatarPreview,
  },

  /**
   * sh (stake holder)
   * 1 Individual
   * 2 Partner
   * 3 Corporate
   */
  data() {
    return {
      loginUserObj: {},
      shId: encodeDecodeService.cyDecrypt(this.$route.params.shId), // Stake Holder id it would be 1,2,3
      id: encodeDecodeService.cyDecrypt(this.$route.params.id),
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      parentId: encodeDecodeService.cyDecrypt(this.$route.params.parentId),
      customerId: encodeDecodeService.cyDecrypt(this.$route.params.customerId),
      checkId: encodeDecodeService.cyDecrypt(this.$route.params.checkId), // Product id
      mode: encodeDecodeService.cyDecrypt(this.$route.params.mode),
      stepId: 4,

      loading: false,
      dropzoneOptions: {
        url: `${store.state.apiURL}/police-check/document-upload`,
        headers: {
          Authorization: `Bearer ${authService.getAccessToken()}`,
        },
        thumbnailWidth: 75,
        maxFilesize: 20,
        uploadMultiple: true,
        maxFiles: 10,
        paralleUploads: 10,
        previewsContainer: false,
        acceptedFiles: ".pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .png",
      },
      i_have_a_previous_name: "No",
      previous_names: [],
      i_have_same_address: "Yes",
      previous_address: [],
      checkValidation: [],

      optionsAliasNameYesNo: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      optionsPreviousAddressYesNo: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getPoliceCheckSingle"]),
  },
  methods: {
    ...mapActions(["fetchStepId", "fetchPoliceCheckSingle"]),
    fnPreviousStep() {
      // Individual Customer
      if (this.getPoliceCheckSingle.sh_id == 1) {
        this.$router.push({
          name: "checkPoliceStep3IU",
          params: {
            id: encodeDecodeService.cyEncrypt(this.id),
            shId: encodeDecodeService.cyEncrypt(this.shId),
            projectId: encodeDecodeService.cyEncrypt(this.projectId),
            parentId: encodeDecodeService.cyEncrypt(this.parentId),
            customerId: encodeDecodeService.cyEncrypt(this.customerId),
            checkId: encodeDecodeService.cyEncrypt(this.checkId),
            mode: encodeDecodeService.cyEncrypt(this.mode),
          },
        });
      }

      // Corporate customer
      // To Skip payment step for the Partner and partner staff
      if (
        this.getPoliceCheckSingle.sh_id == 2 ||
        this.getPoliceCheckSingle.sh_id == 3
      ) {
        this.$router.push({
          name: "checkPoliceStep2CU",
          params: {
            id: encodeDecodeService.cyEncrypt(this.id),
            shId: encodeDecodeService.cyEncrypt(this.shId),
            projectId: encodeDecodeService.cyEncrypt(this.projectId),
            parentId: encodeDecodeService.cyEncrypt(this.parentId),
            customerId: encodeDecodeService.cyEncrypt(this.customerId),
            checkId: encodeDecodeService.cyEncrypt(this.checkId),
            mode: encodeDecodeService.cyEncrypt(this.mode),
          },
        });
      }

      // To Skip payment step for the Partner and partner staff
      // if (this.loginUserObj.role_id == 6 || this.loginUserObj.role_id == 7) {
      //   this.fetchStepId(2);
      // } else {
      //   this.fetchStepId(3);
      // }
    },
    fnNextStep() {
      this.checkValidation = this.fnCheckValidation();

      if (this.checkValidation.length === 0) {
        this.fnUpdateAdditionalInformation();

        // Individual Customer
        if (this.getPoliceCheckSingle.sh_id == 1) {
          this.$router.push({
            name: "checkPoliceStep5IU",
            params: {
              id: encodeDecodeService.cyEncrypt(this.id),
              shId: encodeDecodeService.cyEncrypt(this.shId),
              projectId: encodeDecodeService.cyEncrypt(this.projectId),
              parentId: encodeDecodeService.cyEncrypt(this.parentId),
              customerId: encodeDecodeService.cyEncrypt(this.customerId),
              checkId: encodeDecodeService.cyEncrypt(this.checkId),
              mode: encodeDecodeService.cyEncrypt(this.mode),
            },
          });
        }

        // Corporate customer
        if (
          this.getPoliceCheckSingle.sh_id == 2 ||
          this.getPoliceCheckSingle.sh_id == 3
        ) {
          this.$router.push({
            name: "checkPoliceStep5CU",
            params: {
              id: encodeDecodeService.cyEncrypt(this.id),
              shId: encodeDecodeService.cyEncrypt(this.shId),
              projectId: encodeDecodeService.cyEncrypt(this.projectId),
              parentId: encodeDecodeService.cyEncrypt(this.parentId),
              customerId: encodeDecodeService.cyEncrypt(this.customerId),
              checkId: encodeDecodeService.cyEncrypt(this.checkId),
              mode: encodeDecodeService.cyEncrypt(this.mode),
            },
          });
        }

        // this.fetchStepId(5);
      }
    },

    // Dropzone docuemt upload
    fnSendingDocuemt(file, xhr, formData) {
      this.loading = true;
      formData.append("pId", this.id);
      formData.append("documentTypeId", 41);
    },

    // Dropzone docuemt upload after
    fnAfterSendingDocuemt(file) {
      this.fetchPoliceCheckSingle(this.id);
      this.loading = false;
    },

    fnDeletePhoto: async function () {
      let obj = {
        documentTypeId: 41,
      };

      try {
        await policeCheckService.removeDoc(this.id, obj);
        this.fetchPoliceCheckSingle(this.id);

        this.error = {};

        messageService.fnToastSuccess("Photo remove successfully!");
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Photo remove",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Photo remove",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Photo remove",
              "Something wrong, please try again!"
            );
            break;
        }
      }
    },
    fnDeletePreviousNames: async function (i) {
      if (this.previous_names.length > 0) {
        this.previous_names.splice(i, 1);

        let obj = {
          previous_names_status: this.i_have_a_previous_name,
          previous_names: this.previous_names,
        };

        try {
          this.errors = {};

          await policeCheckService.addPreviousNames(this.id, obj);
          this.fetchPoliceCheckSingle(this.id);
          messageService.fnToastSuccess("Previous name - Successfully added");
        } catch (error) {
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              break;

            case 500:
              messageService.fnSweetAlertErrorToast(
                "Previous name",
                error.response.data.message
              );
              break;

            default:
              messageService.fnSweetAlertErrorToast(
                "Previous name",
                "Something wrong"
              );
              break;
          }
        }
      }
    },

    fnDeletePreviousAddress: async function (i) {
      if (this.previous_address.length > 0) {
        this.previous_address.splice(i, 1);

        let obj = {
          previous_address_status: this.i_have_same_address,
          previous_address: this.previous_address,
        };

        try {
          this.errors = {};

          await policeCheckService.addPreviousAddress(this.id, obj);
          this.fetchPoliceCheckSingle(this.id);
          messageService.fnToastSuccess(
            "Previous address - Successfully deleted"
          );
        } catch (error) {
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              break;

            case 500:
              messageService.fnSweetAlertErrorToast(
                "Previous name",
                error.response.data.message
              );
              break;

            default:
              messageService.fnSweetAlertErrorToast(
                "Previous name",
                "Something wrong"
              );
              break;
          }
        }
      }
    },

    fnUpdateAdditionalInformation: async function () {
      let obj = {
        previous_names_status: this.i_have_a_previous_name,
        previous_address_status: this.i_have_same_address,
      };
      try {
        this.errors = {};

        const response = await policeCheckService.updateAdditionalInformation(
          this.id,
          obj
        );
        this.fetchPoliceCheckSingle(this.id);
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Previous address",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Previous address",
              "Something wrong"
            );
            break;
        }
      }
    },

    fnCheckValidation() {
      let msg = [];

      if (
        (this.i_have_a_previous_name === "Yes" &&
          this.previous_names == null) ||
        (this.i_have_a_previous_name === "Yes" &&
          this.previous_names.length === 0)
      ) {
        msg.push("At least one previous name information is required");
      }

      if (
        (this.i_have_same_address === "No" && this.previous_address == null) ||
        (this.i_have_same_address === "No" &&
          this.previous_address.length === 0)
      ) {
        msg.push("At least one previous address information is required");
      }

      if (this.getPoliceCheckSingle.photo == null) {
        msg.push("Upload your photo, is required");
      }

      return msg;
    },

    // To get file extension
    fnGetExtension(fileName) {
      return miscellaneousService.getExtension(fileName);
    },
  },
  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchPoliceCheckSingle(this.id);
  },
  mounted() {
    // if (this.getPoliceCheckSingle.previous_names) {
    //   this.previous_names = this.getPoliceCheckSingle.previous_names;
    // }
    // if (this.getPoliceCheckSingle.previous_address) {
    //   this.previous_address = this.getPoliceCheckSingle.previous_address;
    // }
  },
  watch: {
    getPoliceCheckSingle(val) {
      this.previous_names = val.previous_names;
      this.i_have_a_previous_name = val.previous_names_status;

      this.previous_address = val.previous_address;
      this.i_have_same_address = val.previous_address_status;
    },
  },
};
</script>
